<template>
  <div class="setting">
      <div class="left_account">
          <el-form>
              <div><p style="font-size: large">{{$t('message.email.addaccount')}}</p></div>
              <div class="card">
                  <el-card class="box-card">
                      <div style="text-align:center">
                          <el-button style="border: white" @click="addaccount">
                              <img style=" width:100px; height: 100px" src="../../assets/img/addaccount.png" />
                          </el-button>
                      </div>
                  </el-card>
              </div>
             <div><p style="font-size: large">{{$t('message.email.accountlist')}}</p></div>
             <div style="overflow-y: auto;height: 450px;">
              <ul>
                <li v-for="(item) in accountandemail" :key="item.id">
                  <accountcard @deleteaccount="deleteaccount" :dataObj="item"></accountcard>
                </li>
              </ul>
            </div>
          </el-form>
      </div>
      <el-image
      v-show="!visible"
        style="width: 500px; height: 300px; margin-top: 50px; margin-left: 100px"
        :src="require('../../assets/img/process.png')"
        fit="fill"
    ></el-image>
      <div v-show="visible" class="right_set">
          <el-form :rules="rules">
              <div><p style="font-size: large">{{$t('message.email.mailsetting')}}</p></div>
              <div>
                  <el-card class="box-card" :class="[isActive == -1 ?'change':'changs']">
                      <template #header>
                          <div class="card-header">
                              <p style="font-size: larger;">{{$t('message.email.Internetmailsetting')}}</p>
                              <p>&emsp;&emsp;{{$t('message.email.sentence4')}}</p>
                          </div>
                      </template>
                      <el-row>
                          <div class="right_box">
                              <div class="col_box">
                                  <el-row>
                                      <p style="font-size: large">{{$t('message.email.userinformation')}}</p>
                                  </el-row>
                                  <el-row>
                                      <el-col  :span="12">
                                          <el-form-item :label="$t('message.email.mailaddress')" label-width="150px" prop="account_email" >
                                            <el-input type="password" style="position:fixed;bottom:-9999px;"> </el-input>
                                              <el-input v-model="emailAccountForm.account_email" style="width:100%" >
                                              </el-input>
                                          </el-form-item>
                                      </el-col>
                                    <el-col  :span="12">
                                      <el-form-item :label="$t('message.email.Password')" label-width="150px">
                                        <el-input type="password" style="position:fixed;bottom:-9999px;"> </el-input>
                                        <el-input v-model="emailAccountForm.login_passwd" type="password" show-password clearable style="width:90%" >
                                        </el-input>
                                      </el-form-item>
                                    </el-col>
                                  </el-row>
                              </div>
                              <div class="col_box">
                                  <el-row>
                                      <p style="font-size: large">{{$t('message.email.serverinformation')}}</p>
                                  </el-row>
                                  <el-row>
                                      <el-col :span="12">
                                          <el-form-item :label="$t('message.email.mailservertype')" label-width="180px">
                                              <el-select v-model="emailAccountForm.mail_server_provider"   style="width: 80%">
                                                  <el-option
                                                          v-for="item in servertype"
                                                          :key="item.value"
                                                          :label="item.label"
                                                          :value="item.value">
                                                  </el-option>
                                              </el-select>
                                          </el-form-item>
                                      </el-col>
                                      <el-col  :span="12">
                                          <el-form-item :label="$t('message.email.accounttype')" label-width="180px">
                                              <el-select v-model="emailAccountForm.server_protocol_type"  @change="servertype_port" style="width: 80%">
                                                  <el-option
                                                          v-for="item in accounttype"
                                                          :key="item.value"
                                                          :label="item.label"
                                                          :value="item.value">
                                                  </el-option>
                                              </el-select>
                                          </el-form-item>
                                      </el-col>
                                  </el-row>
                                  <el-row>
                                      <el-col :span="10">
                                          <el-form-item :label="$t('message.email.IncomingServer')" label-width="180px">
                                              <el-input v-model="emailAccountForm.incoming_server_port" style="width: 90%" >
                                              </el-input>
                                          </el-form-item>
                                      </el-col>
                                      <el-col :span="2">
                                          <el-form-item>
                                              <el-checkbox v-model="emailAccountForm.is_ssl" @change="isssl" label="SSL"></el-checkbox>
                                          </el-form-item>
                                      </el-col>
                                      <el-col  :span="12">
                                          <el-form-item :label="$t('message.email.IncomingMailServer')" label-width="200px">
                                              <el-input v-if="this.emailAccountForm.mail_server_provider=='Custom'"  v-model="emailAccountForm.incoming_mail_server" style="width: 80%" >
                                              </el-input>
                                            <el-input v-else v-model="incoming_mail_server" style="width: 80%" >
                                            </el-input>
                                          </el-form-item>
                                      </el-col>
                                      <!--<el-col :span="6">-->
                                          <!--<el-button size="mini" style="margin-top: 5px">{{$t('message.email.Usedefault')}}</el-button>-->
                                      <!--</el-col>-->
                                  </el-row>
                              </div>
                          </div>
                          <div  class="right_box">
                              <el-row>

                                    <el-col>
                                        <div class="col_box">
                                            <el-row>
                                                <p style="font-size: large">{{$t('message.email.AccountTesting')}}</p>
                                            </el-row>
                                            <el-row>
                                                <div style="margin: 1%; background-color: white">
                                                    <p>&emsp;&emsp;{{$t('message.email.sentence6')}}</p>
                                                </div>
                                            </el-row>
                                            <el-row>
                                                <el-button size="mini" style="margin-left:73%;margin-bottom: 10px" @click="connecttest">{{$t('message.email.TestAccountSetting')}}</el-button>
                                            </el-row>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                                <div style="margin-left: 90%">
                                    <el-row>
                                        <el-button @click="addUserAccount">{{$t('message.email.finish')}}</el-button>
                                    </el-row>
                                </div>

                        </el-row>
                    </el-card>
                </div>
            </el-form>
        </div>

    </div>
</template>

<script>
  import accountcard from '../../components/account_email/Accountcard'
  import {getemailaccount, adduseraccount,connectaccount} from '../../api/emailextract'
  export default {
    components:{accountcard},
    data(){
      const validatesenderEmail = (rule,value,callback) => {
          if (this.emailAccountForm.account_email !== '') {
            var reg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/
            if (!reg.test(this.emailAccountForm.account_email)) {
              callback(new Error(window.vm.$t('message.email.please_email')));
            } else {
              callback();
            }
          } else {
            callback();
          }
      };
      return{
        visible:false,
        accountandemail:[],
        timeout:0,
        emailAccountForm:{
          account_email:'',
          server_protocol_type:'',
          mail_server_provider:'Custom',
          incoming_mail_server:'',
          login_passwd:'',
          server_timeout:0,
          incoming_server_port:'',
          is_ssl:false
        },
        servertype:[
          {
            value: 'Custom',
            label: 'Custom'
          },
          {
            value:  'Gmail',
            label: 'Gmail'
          },
          {
            value: 'Outlook',
            label: 'Outlook'
          },
          {
            value: 'Yahoo',
            label: 'Yahoo'
          },
          {
            value: 'QQ',
            label: 'QQ'
          }, {
            value: '163',
            label: '163'
          },
          {
            value:'dylnet',
            label:'dylnet'
          }
        ],
        accounttype:[
          {
            value:'POP3',
            label:'POP3'
          },
          {
            value:'IMAP',
            label:'IMAP',
          }

        ],

        bar:'',
        src:'../../src/assets/addaccount.png',
        isActive: -1,
        rules:{
          account_email:[{validator:validatesenderEmail,trigger: ['blur','change']}]
        }
      }
    },
    computed:{
      incoming_mail_server:function() {
        if(this.emailAccountForm.server_protocol_type == 'IMAP'){
          if(this.emailAccountForm.mail_server_provider == 'QQ'){
            return 'imap.qq.com'
          }
          else if(this.emailAccountForm.mail_server_provider == '163'){
            return 'imap.163.com'
          }else if(this.emailAccountForm.mail_server_provider == 'YaHoo'){
            return 'imap.mail.yahoo.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Outlook'){
            return 'imap.outlook.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Gmail'){
            return 'imap.gmail.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Custom'){
            return ''
          }else if(this.emailAccountForm.mail_server_provider == 'dylnet'){
            return 'imap.dylnet.com'
          }
        }
        else if(this.emailAccountForm.server_protocol_type == 'POP3'){
          if(this.emailAccountForm.mail_server_provider == 'QQ'){
            return 'pop.qq.com'
          }
          else if(this.emailAccountForm.mail_server_provider == '163'){
            return 'pop.163.com'
          }else if(this.emailAccountForm.mail_server_provider == 'YaHoo'){
            return 'pop.mail.yahoo.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Outlook'){
            return 'pop.outlook.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Gmail'){
            return 'pop.gmail.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Custom'){
            return ''
          }
        }
      }
    },
    methods:{
      deleteaccount(){
        this.getMailList()
      },
      addaccount(){
        this.visible = true
        this.isActive = 1
      },
      isssl(value){
       // console.log(value)
        if(value == true){
          if(this.emailAccountForm.server_protocol_type == 'IMAP') this.emailAccountForm.incoming_server_port = 993
          else if(this.emailAccountForm.server_protocol_type == 'POP3') this.emailAccountForm.incoming_server_port = 995
        }
        else if(value == false){
          if(this.emailAccountForm.server_protocol_type == 'IMAP') this.emailAccountForm.incoming_server_port = 143
          else if(this.emailAccountForm.server_protocol_type == 'POP3') this.emailAccountForm.incoming_server_port = 110
        }
      },
      servertype_port(value){
        if(this.emailAccountForm.is_ssl == true){
          if(value == 'IMAP') this.emailAccountForm.incoming_server_port = 993
          else if(value == 'POP3') this.emailAccountForm.incoming_server_port = 995
        }
        else if(this.emailAccountForm.is_ssl == false){
          if(value == 'IMAP') this.emailAccountForm.incoming_server_port = 143
          else if(value == 'POP3') this.emailAccountForm.incoming_server_port = 110
        }
      },
    //  获取账户邮箱列表
      getMailList(){
        getemailaccount().then(response =>{
          if(response.error_code == 200){
            if(response.data!=null){
              this.accountandemail = response.data.results
            }
          }
        })
      },
      //连接邮箱测试
      connecttest(){
        if(this.emailAccountForm.mail_server_provider!='Custom'){
          this.emailAccountForm.incoming_mail_server = this.incoming_mail_server
        }
        this.emailAccountForm.server_timeout = 60 * this.timeout
        connectaccount(this.emailAccountForm).then(response =>{
        //   if(response.error_code == 200){
        //     this.$message.success(window.vm.$t('message.email.emailconnect'))
        //   }else if (response.error_code==500){
        //     this.$message.warning(response.error_msg)
        //   }
        // })
        if(response.error_code == 200){
          if(response.data.status==1){
            this.$message.success(response.data)
          }else{
            this.$message.warning(response.data)
          }
        }else if (response.error_code==500){
          this.$message.warning(response.data)
        }
      })
      },
    //  添加账户邮箱
      async addUserAccount(){
        if(this.emailAccountForm.mail_server_provider!='Custom'){
          this.emailAccountForm.incoming_mail_server = this.incoming_mail_server
        }
        this.emailAccountForm.server_timeout = 60 * this.timeout
        await adduseraccount(this.emailAccountForm).then(response =>{
          if(response.error_code == 200){
            this.$message.success(window.vm.$t('message.email.accountadd'))
            this.visible = false
          }
          else{
            this.$message.warning("信息填写有误，请重新修改！")
          }
        })
        await this.getMailList()
      }

    },
    created(){
      this.getMailList()
    }

  };
</script>

<style lang="scss">
    .setting{
        height: 100%;
        width: 100%;
        .el-input__inner{
            height:30px;
        }
        .left_account{
            font-size: larger;
            float: left;
            width: 35%;
            height: 100%;
            .btn_box{
                margin-top: 5px;
                margin-left:50px ;
            }
            .card{
                margin-bottom: 5%;
            }
        }
        .right_set{
            margin-left: 33%;
            width: 60%;
            height: 100%;
            .right_box{
                width: 100%;
            }
            .el-card__header{
                padding: 11px;
            }
            .change {
                border: white;
            }
            .changs {
                border: 5px solid #409EFF;
            }
            .el-card__body{
                padding: 10px;
            }
            .col_box{
                margin: 3%;
                background-color: rgba(212, 219, 208, 0.28);
            }
        }

    }
</style>