<template>
    <div class="card">

        <el-card  class="box-card">
            <div>
                <i class="el-icon-user-solid"></i>
                {{$t('message.email.account')}}：<span>{{dataObj.account_nickname}}</span>
            </div>
            <div>
                <i class="el-icon-message"></i>
              {{$t('message.email.mailbox')}}：<span>{{dataObj.account_email}}</span>
            </div>
<!--          设置-->
            <div v-if="temp == '1'" class="btn_box">
                <el-button type="primary" size="small" class="el-icon-download" @click="gotodownload">{{$t('message.email.maildownload')}}</el-button>
                <el-button type="primary" size="small" class="el-icon-upload2" @click="gotoextract">{{$t('message.email.extractemail')}}</el-button>
                <el-button type="danger" size="small" class="el-icon-delete" @click="deleteAccount">{{$t('message.email.accountdelete')}}</el-button>
            </div>
<!--          下载-->
            <div v-else-if="temp == '2'" class="btn_box">
                <el-button type="success" size="small" class="el-icon-edit-outline" @click="gotoset">{{$t('message.email.mailsettings')}}</el-button>
                <el-button type="primary" size="small" class="el-icon-upload2" @click="gotoextract">{{$t('message.email.extractemail')}}</el-button>
                <el-button type="danger" size="small" class="el-icon-delete" @click="deleteAccount">{{$t('message.email.accountdelete')}}</el-button>
            </div>
<!--          抽取-->
            <div v-else-if="temp == '3'" class="btn_box">
                <el-button type="success" size="small" class="el-icon-edit-outline" @click="gotoset">{{$t('message.email.mailsettings')}}</el-button>
                <el-button type="primary" size="small" class="el-icon-download" @click="gotodownload">{{$t('message.email.maildownload')}}</el-button>
                <el-button type="danger" size="small" class="el-icon-delete" @click="deleteAccount">{{$t('message.email.accountdelete')}}</el-button>
            </div>
<!--          emailaccount-->
            <div v-else class="btn_box">
                <el-button type="success" size="small" class="el-icon-edit-outline" @click="gotoset">{{$t('message.email.mailsettings')}}</el-button>
                <el-button type="primary" size="small" class="el-icon-download" @click="gotodownload">{{$t('message.email.maildownload')}}</el-button>
                <el-button type="primary" size="small" class="el-icon-upload2" @click="gotoextract">{{$t('message.email.extractemail')}}</el-button>
                <el-button type="danger" size="small" class="el-icon-delete" @click="deleteAccount">{{$t('message.email.accountdelete')}}</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
  import {deleteuseraccount} from '../../api/emailextract'
  export default {
    inject:['reload'],
    props:{
      temp:Number,
      dataObj:Object
    },
    watch: {
      '$route' (to, from) { //监听路由是否变化
          this.reload();
      }
    },
    methods:{
      gotoset(){
        // this.reload()
        this.$router.push(`/extract/emailset/${this.dataObj.id}`)

      },
      gotodownload(){
        this.$router.push(`/extract/emaildownload/${this.dataObj.id}`)
      },
      gotoextract(){
        this.$router.push(`/extract/emailextract/${this.dataObj.id}`)
        //console.log('dataObj.id:', this.dataObj.id);
      },
      deleteAccount(){
        deleteuseraccount(this.dataObj.id).then(response =>{
          if(response.error_code ==200){
            this.$message.success(window.vm.$t('message.deletesucc'))
            this.$emit('deleteaccount')
            //this.$router.push(`/extract/emailextract`)
          }
        })
      }
    },
    created(){
    //  console.log(this.temp)
    },
   
   
  };
</script>

<style scoped>
.el-button--small{
  padding: 0 3px;
}
</style>